var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"ft",attrs:{"text":"List Of Approved Lube Requests"}})],1),(_vm.approvedOrders.length > 0)?_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},_vm._l((_vm.approvedOrders),function(approvedOrder,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('h3',{staticClass:"font-weight-small text-capitalize mx-2 my-2 font-italic"},[_vm._v(" "+_vm._s(approvedOrder[0])+" ")]),_c('v-data-table',{staticClass:"elevation-1 ft",attrs:{"headers":_vm.headers,"items":approvedOrder[1],"item-key":"product","loading-text":"Loading... Please wait","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1",attrs:{"color":"blue","size":"40"}},[(item.attendantId)?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.attendantId.name.charAt(0)))]):_vm._e()])]}},{key:"item.lubesOrdersLineItem",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.lubesOrdersLineItem.length))])]}},{key:"item.isOrderReviewed",fn:function(ref){
var item = ref.item;
return [(item.isOrderReviewed)?_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isOrderReviewed ? '#9efb9e66' : '#f98c6b',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isOrderReviewed
                      ? 'rgba(3, 91, 3, 0.83)'
                      : '#862c00'
                  })},[_vm._v("Reviewed")])]):_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s("Not Reviewed "))])])]}},{key:"item.isOrderApproved",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isOrderApproved ? '#9efb9e66' : 'rgb(247, 189, 172)',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isOrderApproved
                      ? 'rgba(3, 91, 3, 0.83)'
                      : 'rgb(238, 78, 0)'
                  })},[_vm._v("Approved")])])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","small":"","to":{
                      name: 'master.lubes.order.details',
                      params: { id: item.id }
                    }}},on),[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("View Order Details")])])]}}],null,true)})],1)]],2)}),1):_c('v-row',{staticStyle:{"min-height":"500px"},attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 ",staticStyle:{"align-items":"center"}},[_c('img',{staticClass:"mb-3",staticStyle:{"width":"180px"},attrs:{"src":require("@/assets/pallets.svg")}}),_c('span',{staticClass:"ft font-weight-medium font-size-md pt-3"},[_vm._v("No approved "),_c('kbd',{staticClass:"ml-1"},[_vm._v("lube order requests")]),_vm._v(" available right now.")]),_c('span',{staticClass:" ft font-weight-medium font-size-sm pt-2"},[_vm._v("All approved requests will show up here !!!")])])]),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }